import {
  ButtonClassic,
  ButtonGroup,
  PageContent,
  moneyFilter,
  copyToClipboard,
  InfiniteLoader,
  percentage,
} from "@lde/core_lde_vue";

import ButtonStatusApprobation from "@/components/buttons/ButtonStatusApprobation.vue";
import BlocLignesCatalogues from "@/components/catalogues/BlocLignesCatalogues.vue";
import TableTotal from "@/components/table/TableTotal.vue";
import ListeActions from "@/components/listes_devis_commandes/ListeActions.vue";
import PiecesJointes from "@/components/listes_devis_commandes/PiecesJointes.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import Timeline from "@/components/timeline/Timeline.vue";
import TimelineLabel from "@/components/timeline/TimelineLabel.vue";
import ModalPiecesJointes from "@/components/modals/ModalPiecesJointes.vue";

import IconPDF from "@/components/icons/IconPDF.vue";
import IconFileDevis from "@/components/icons/IconFileDevis.vue";
import IconEstablishment from "@/components/icons/IconEstablishment.vue";
import IconPanierFleche from "@/components/icons/IconPanierFleche.vue";

import {
  UilShareAlt,
  UilEuroCircle,
  UilBooks,
} from "@iconscout/vue-unicons";
import { UisExclamationTriangle } from "@iconscout/vue-unicons-solid";

import { couleurSelonTaux } from "@/modules/utils";
import actionIsAllowed from "@/modules/devis_utils";

import { mapGetters } from "vuex";

import Api from "@/modules/axios";

export default {
  components: {
    PageContent,
    ButtonClassic,
    ButtonGroup,
    ButtonStatusApprobation,
    BlocLignesCatalogues,
    TableTotal,
    ListeActions,
    PiecesJointes,
    ContextMenu,
    Timeline,
    TimelineLabel,
    ModalPiecesJointes,
    InfiniteLoader,
    IconPDF,
    IconFileDevis,
    IconEstablishment,
    IconPanierFleche,
    UilShareAlt,
    UisExclamationTriangle,
    UilEuroCircle,
    UilBooks,
  },
  data() {
    return {
      objectInfos: null,
      product: {
        offre_defaut: {},
      },
      isLoading: true,
      budgetType: null,
      currentUrl: window.location.href,
      attachments: [],
      allCgv: [],
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "user", "isHorsMarche", "hasPerm", "hasPerms"]),
    lignesNumerique() {
      return this.objectInfos?.lignes.filter(({ offre }) => offre?.manuel_numerique);
    },
    lignesPapier() {
      return this.objectInfos?.lignes.filter(({ offre }) => offre?.article);
    },
    lignesFourniture() {
      return this.objectInfos?.lignes.filter(({ offre }) => offre?.fourniture);
    },
    navTitles() {
      const titles = [];

      if (this.lignesNumerique?.length) {
        titles.push({ label: this.$t("menu.ressources-numeriques"), anchorId: "ressources_numeriques" });
      }

      if (this.$route.name === "commandes_factures_commandes_item") {
        if (this.lignesPapier?.length || this.lignesFourniture?.length) {
          titles.push({ label: this.$t("commande.papier-et-fournitures"), anchorId: "papier_fournitures" });
        }
      } else {
        if (this.lignesPapier?.length) {
          titles.push({ label: this.$t("menu.livres-et-manuels-papier"), anchorId: "livres_papier" });
        }
        if (this.lignesFourniture?.length) {
          titles.push({ label: this.$t("menu.fournitures-et-papeterie"), anchorId: "fournitures_papeterie" });
        }
      }
      return titles;
    },
    catalogues() {
      const numeriqueWithRoute = this.lignesNumerique?.map((ligne) => {
        ligne._routeName = "catalogues_ressources_numeriques_item";
        return ligne;
      });

      const papierWithRoute = this.lignesPapier?.map((ligne) => {
        ligne._routeName = "catalogues_livres_manuels_papier_item";
        return ligne;
      });

      const fournitureWithRoute = this.lignesFourniture?.map((ligne) => {
        ligne._routeName = "catalogues_fournitures_papeterie_item";
        return ligne;
      });

      const blocs = [
        {
          key: "numerique",
          id: "ressources_numeriques",
          title: this.$t("menu.ressources-numeriques"),
          lignes: numeriqueWithRoute,
        },
      ];

      if (this.$route.name === "commandes_factures_commandes_item") {
        blocs.push(
          {
            key: "papier_fourniture",
            id: "papier_fournitures",
            title: this.$t("commande.papier-et-fournitures"),
            lignes: papierWithRoute?.concat(fournitureWithRoute),
          },
        );
      } else {
        blocs.push(
          {
            key: "papier",
            id: "livres_papier",
            title: this.$t("menu.livres-et-manuels-papier"),
            lignes: papierWithRoute,
          },
          {
            key: "fourniture",
            id: "fournitures_papeterie",
            title: this.$t("menu.fournitures-et-papeterie"),
            lignes: fournitureWithRoute,
          },
        );
      }

      // Hack pour récupérer les infos de la LDC qui concerne les etiquettes (prix unit, remise..)
      const etiquette = this.objectInfos?.lignes.find((ligne) => ligne?.offre?.ean === "9789000008636");
      const lignesEtiquettes = this.objectInfos?.lignes.filter((ligne) => ligne.etat_code !== undefined) || [];
      let lignesLde = this.objectInfos?.lignes.filter(
        (ligne) => (ligne.offre?.ean && ligne.offre?.article === undefined),
      );

      if (lignesEtiquettes.length) {
        lignesLde = lignesLde.filter((ligne) => ligne?.offre?.ean !== "9789000008636");
      }

      blocs.push(
        {
          key: "autres",
          id: "autres",
          title: this.$t("general.autres"),
          lignes: lignesLde,
        },
      );

      if (etiquette) {
        lignesEtiquettes.forEach((ligne) => {
          ligne.prix_unitaire_ttc = etiquette.prix_unitaire_ttc;
          ligne.prix_unitaire_ht = etiquette.prix_unitaire_ht;
          ligne.prix_editeur_ttc = etiquette.prix_editeur_ttc;
          ligne.prix_editeur_ht = etiquette.prix_editeur_ht;
          ligne.remise = etiquette.remise;
          ligne.order_label = etiquette.order_label;
        });
      }

      blocs.push(
        {
          key: "etiquettes",
          id: "etiquettes",
          title: this.$t("liste.etiquettes"),
          lignes: lignesEtiquettes,
        },
      );

      return blocs;
    },
    hasNumerique() {
      return this.catalogues.find((cat) => cat.key === "numerique")?.lignes.length > 0;
    },
    primaryStatusLabel() {
      let label;

      if (this.objectInfos.statut_affiche === "expire") {
        return this.$t("action.actualiser-et-renouveler-le-devis");
      }
      if (this.isCurrentUserAskedToModif) {
        return this.$t("action.enregistrer-et-renvoyer");
      }
      if (this.isSelfEditing) {
        return this.$t("action.enregistrer");
      }

      switch (this.objectInfos.statut_affiche) {
        case "attente_selection":
          label = this.$t("devis.devis-est-en-attente");
          break;
        case "attente_validation":
          if (this.isAllowed("valider_etablissement")) {
            label = this.$t("action.valider-devis");
          } else {
            label = this.$t("devis.devis-est-en-attente");
          }
          break;
        case "attente_modification":
          label = this.$t("devis.devis-doit-etre-modifie");
          break;
        case "refus":
          label = this.$t("devis.devis-est-rejete");
          break;
        case "attente_soumission":
          label = this.$t("devis.devis-est-valide");
          break;
        case "attente_approbation":
          if (!this.isHorsMarche) {
            if (this.hasPerm("can_devis_to_cmd")) {
              label = this.$t("action.approuver-la-commande");
            } else if (this.hasPerm("can_liste_to_devis")) {
              label = this.$t("devis.devis-est-soumis");
            }
          }
          break;
        case "attente_commande":
          if (this.isHorsMarche) {
            label = this.$t("devis.devis-est-valide");
          } else if (this.hasPerm("can_liste_to_devis")) {
            label = this.$t("devis.devis-est-approuve");
          }
          break;
        case "attente_expedition":
        case "attente_livraison":
          label = this.$t("commande.commande-est-en-cours");
          break;
        case "annulee":
          label = this.$t("commande.la-commande-a-ete-annulee");
          break;
        default:
          label = this.$t("commande.commande-a-ete-livree");
          break;
      }
      return label;
    },
    primaryStatusButton() {
      let variant;

      if (this.objectInfos.statut_affiche === "expire") {
        return "secondary";
      }

      if (this.isCurrentUserAskedToModif
        || (this.hasPerm("can_transmettre_devis") && this.isSelfEditing)) {
        return "positive";
      }

      if (this.isSelfEditing) {
        return "positive";
      }

      switch (this.objectInfos.statut_affiche) {
        case "attente_selection":
          variant = "white";
          break;
        case "attente_validation":
          if (!this.isHorsMarche && (this.hasPerm("can_transmettre_devis") || (this.hasPerm("can_devis_to_cmd")))) {
            variant = "primary";
          } else {
            variant = "white";
          }
          break;
        case "attente_modification":
          variant = "warning";
          break;
        case "refus":
          variant = "error";
          break;
        case "attente_soumission":
          variant = "white";
          break;
        case "attente_approbation":
          if (this.hasPerm("can_devis_to_cmd")) {
            variant = "primary";
          } else if (this.hasPerm("can_transmettre_devis")) {
            variant = "white";
          } else if (this.hasPerm("can_liste_to_devis")) {
            variant = "success";
          }
          break;
        case "attente_commande":
          if (this.isHorsMarche || this.hasPerm("can_transmettre_devis")) {
            variant = "white";
          } else if (this.hasPerm("can_liste_to_devis")) {
            variant = "success";
          }
          break;
        default:
          variant = "success";
          break;
      }
      return variant;
    },
    isPrimaryBtnDisabled() {
      if (this.objectInfos.statut_affiche === "expire") {
        return false;
      }
      if (this.isSelfEditing) {
        return false;
      }

      let disabled = true;

      if (this.isAllowed("valider_etablissement")) {
        disabled = this.objectInfos.depasse_dotation;
      } else if (this.isAllowed("approuver_region")) {
        disabled = false;
      } else if (this.isAllowed("commander")) {
        disabled = !this.lignesIndisponibles.length;
      }

      return disabled && !this.isSelfEditing;
    },
    secondaryStatusLabel() {
      if (this.isAllowed("soumettre_region")) {
        return this.$t("action.soumettre");
      }
      if (this.isAllowed("commander")) {
        return this.$t("action.commander");
      }
      return null;
    },
    buttonSecondaryIcon() {
      switch (this.objectInfos.statut_affiche) {
        case "attente_validation":
        case "attente_soumission":
          return IconFileDevis;
        case "attente_approbation":
        case "attente_commande":
          return IconPanierFleche;
        default:
          return null;
      }
    },
    budgetStatus() {
      if (this.objectInfos.budget
        && (this.objectInfos.budget.dotation !== 0 || this.objectInfos.budget.dotation !== null)
      ) {
        const percent = percentage(this.objectInfos.budget.enCours, this.objectInfos.budget.dotation);
        return couleurSelonTaux(percent);
      }
      return null;
    },
    isAdoptant() {
      return this.objectInfos.lignes.some((ligne) => ligne.offre?.adoptant);
    },
    lignesIndisponibles() {
      return this.objectInfos.lignes.filter((ligne) => !this.isOfferAvailable(ligne.offre));
    },
    lignesPasDansCatalogue() {
      return this.objectInfos.lignes.filter(({ offre }) => offre?.manuel_numerique && !offre?.catalogue_actif);
    },
    isCommandeClosed() {
      return !this.isHorsMarche && !this.organismeActuel.catalogues_actifs.length;
    },
  },
  methods: {
    moneyFilter,
    copyToClipboard,
    /**
     * Définit les lignes selon le tri en vigueur.
     * @param {Array} lignes Lignes triées selon une colonne spécifique.
     * @param {String} catalogue Type du catalogue selon le tableau.
     */
    handleSort({ lignes, catalogue }) {
      let tmpLines = [];
      if (catalogue === "numerique") {
        tmpLines = this.objectInfos?.lignes.filter(({ offre }) => !offre?.manuel_numerique);
      } else if (catalogue === "papier") {
        tmpLines = this.objectInfos?.lignes.filter(({ offre }) => !offre?.article);
      } else {
        tmpLines = this.objectInfos?.lignes.filter(({ offre }) => !offre?.fourniture);
      }
      this.objectInfos.lignes = [...tmpLines, ...lignes];
    },
    /**
     * Supprime une pièce jointe.
     * @param {Object} file Informations du fichier à supprimer.
     * @returns {Promise}
     */
    deleteAttachment(file) {
      return Api()
        .delete(`/piece_jointe/${file.id}/`)
        .then(() => {
          const index = this.attachments.findIndex(({ id }) => id === file.id);
          if (index !== -1) {
            this.attachments.splice(index, 1);
            this.$toast.success({ title: this.$t("piece-jointe.votre-piece-jointe-a-bien-ete-supprime") });
          }
        })
        .catch(({ message }) => {
          this.$toast.error({ title: message });
        });
    },
    /**
     * Récupère les pièces jointes pour les devis/commandes.
     * @param {String} type Le type des pièces jointes qu'on souhaite récupérer.
     * @returns {Promise}
     */
    fetchAttachments(type) {
      const { id } = this.$route.params;
      const validators = ["devis", "commande"];

      if (!validators.includes(type)) {
        // Ici, c'est une erreur de code, donc pas besoin de trad.
        console.error("Erreur : Le type n'est pas connu.");
        return new Promise((reject) => reject(new Error("Le type n'est pas connu.")));
      }

      const types = {
        bon_commande: this.$t("devis-commande.bon-de-commande"),
        justificatif_adoptant: this.$t("devis-commande.justificatif-adoptant"),
      };

      return Api()
        .get(`/piece_jointe/filter/?modele_cible=${id}&modele_type=${type}`)
        .then(({ data: { results } }) => {
          this.attachments = results.map((attachment) => ({
            id: attachment.id,
            nom: attachment.nom,
            type: attachment.type,
            type_str: types[attachment.type],
            url: attachment.url,
            poids: attachment.poids,
          }));
        });
    },
    /**
     * Vérifie si on peut effectuer l'action d'un bouton spécifique.
     * @param {String} button Bouton à vérifier.
     */
    isAllowed(button) {
      const me = this.objectInfos.user_created === this.user.id;
      return actionIsAllowed(
        this.objectInfos.statut_affiche,
        button,
        me,
      );
    },
    /**
     * Récupère les liens des CGV des distributeurs des produits de la liste.
     * @param {String} source Définit si on est dans une liste ou dans un devis.
     */
    fetchCgvUrl(source) {
      Api().get(`/${source}/${this.objectInfos.id}/cgv_check/`)
        .then(({ data: { distributeurs } }) => {
          this.allCgv = distributeurs;
        });
    },
  },
};
